import { createReducer } from "@reduxjs/toolkit";
import {
  TEMPLATE_LOADING,
  GET_TEMPLATE,
  TEMPLATE_ERROR,
  DELETE_TEMPLATE,
  EDIT_TEMPLATE,
} from "../actions/types";

const initialState = {
  error: "",
  loading: false,
  data: [],
};

const templateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(TEMPLATE_LOADING, (state) => {
      state.loading = true;
    })
    .addCase(TEMPLATE_ERROR, (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    })
    .addCase(GET_TEMPLATE, (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    })
    .addCase(EDIT_TEMPLATE, (state, { payload }) => {
      const index = state.data.findIndex((e) => e?.id === payload?.id);
      state.data[index] = payload;
      state.loading = false;
    })
    .addCase(DELETE_TEMPLATE, (state, { payload }) => {
      state.data = state.data.filter((e) => e?.id !== payload?.id);
      state.loading = false;
    });
});

export default templateReducer;
